import { useEffect, useState } from 'react';

import Image from 'next/image';

import { useRouter } from 'next/router';

import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  IconButton,
  MobileStepper,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import BuildIcon from '@mui/icons-material/Build';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SubjectIcon from '@mui/icons-material/Subject';

import ShareIcon from '@mui/icons-material/Share';

import useSWR from 'swr';

import SwipeableViews from 'react-swipeable-views';

import Counter from './Counter';
import EditCar from './EditCar';

import { NextLinkComposed } from './Link';


const Row = ({ rowName, rowValue, openEdit }) => (
  <Box sx={{ 
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    my: 1,
    px: 1,
    whiteSpace: 'nowrap',
    width: '100%',
    minHeight: 24,
  }}>
    <Typography variant="body2">{rowName}</Typography>
    {rowValue ?
      <Typography
        sx={{
          ml: 2,
          overflow: 'hidden',
        }}
        color="text.secondary"
        textOverflow="ellipsis"
      >{rowValue}</Typography> :
      <IconButton size="small" onClick={openEdit}>
        <AddCircleOutlineIcon sx={{ color: 'primary.light', fontSize: 14 }} />
      </IconButton>
    }
  </Box>
)


const MyCars = ({ handleCarChange }) => {
  const router = useRouter();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  const { uuid } = router.query;

  const [activeStep, setActiveStep] = useState(0);  // maybe get this step from router
  const [editOpen, setEditOpen] = useState(false);

  const { data: cars, error } = useSWR('/api/v3/my-cars/');  // handle error

  const maxSteps = cars ? cars.length : 1;

  useEffect(() => {
    const index = cars ? cars.findIndex( x => x.uuid === uuid ) : 0;
    setActiveStep(index && index >= 0 && index <= maxSteps ? index : 0)
  }, [cars, maxSteps, uuid])

  useEffect(() => {
    if (cars && cars.length === 0) {
      handleCarChange(0);
      return
    }

    const id = cars && cars[activeStep]?.id;
    handleCarChange(id);
  }, [activeStep, cars])

  const closeEdit = () => {
    setEditOpen(false);
  }

  const openEdit = (car) => {
    setEditOpen(car);
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      const step = prevActiveStep + 1;

      if (step === maxSteps) {
        router.push('/create-car/');
        return prevActiveStep;
      } else {
        router.push(`/?uuid=${cars[step].uuid}`, undefined, {shallow: true});
        return step;
      }      
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      const step = prevActiveStep - 1;

      router.push(`/?uuid=${cars[step].uuid}`, undefined, {shallow: true});
      return step;
    });
  };

  const handleStepChange = (step) => {
    if (step === maxSteps) {
      router.push('/create-car/');
    } else {
      router.push(`/?uuid=${cars[step].uuid}`, undefined, {shallow: true});
      setActiveStep(step);
    }
  };

  const shareCar = () => {
    // Edit and complete car card page
    if (navigator.share) {
      navigator.share({
        url: 'https://www.mechanicbuddy.co.za/request-quotes/',
        title: 'MechanicBuddy - Car car made easy',
        text: 'Get cost estimates for repairs from mechanics near you',
      })
      .then(() => console.log('Share was successful.'))
      .catch((error) => console.log('Sharing failed', error));
    } else {
      alert(`Your system doesn't support sharing files.`);
    }
  }

  const manageCarJobs = (car) => {
    if (car.job_count !== 0 ) {
      if (isMobile) {
        router.push(`/my-car-jobs/${car.id}/`);
      } else {
        router.push(`/my-jobs/${car.id}/`);
      }
    } else {
      if (['sold', 'scrapped'].includes(car.status.toLowerCase())) {
        return
      } else {
        router.push(`/create-job/${car.id}/`);
      }
    }
  }

  if (!cars && !error) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '80vh',
          width: '100%',
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    )
  }

  return (
    <Box>
      <Box
        sx={{
          mt: {
            xs: 2,
            md: 4,
          },
          mb: {
            xs: 0,
            md: 2,
          },
          mx: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h4"
          color="primary.light"
        >Cars</Typography>

        {cars?.length > 0 && <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IconButton
            aria-label="previous"
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            <KeyboardArrowLeft fontSize="inherit" />
          </IconButton>
          <Typography
            variant="body2"
            color="text.secondary"
          >{activeStep + 1}/{maxSteps}</Typography>
          {activeStep !== maxSteps - 1 ?
            <IconButton
              aria-label="next"
              onClick={handleNext}
            >
              <KeyboardArrowRight fontSize="inherit" />
            </IconButton> :
            <IconButton
              color="secondary"
              onClick={handleNext}
            >
              <AddIcon fontSize="inherit" />
            </IconButton>
          }
        </Box>}
      </Box>

      {cars?.length > 0 ?
      <>
        <SwipeableViews
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {cars.map((car) => (
            <Box
              key={car.id}
              sx={{
                overflow: 'hidden',
                overflowY: 'scroll',
                width: {
                  xs: '100vw',
                  md: '100%',
                },
                px: 1
              }}
            >
              <Card
                sx={{
                  minWidth: 320,
                  width: '100%',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
                  <Box sx={{ ml: 2 }}>
                    <Typography
                      variant="h6"
                      color="primary"
                      lineHeight={1}
                    >{ car.make } { car.model }</Typography>
                    {car.name && <Typography variant="body2" color="text.secondary">{ car.name }</Typography>}
                  </Box>
                  <Box sx={{ flexGrow: 1 }} />

                  <IconButton color="inherit" onClick={() => shareCar()} size="large">
                    <ShareIcon color="action" />
                  </IconButton>
                </Box>
                <Divider />
                <Box
                  sx={{
                    height: {
                      xs: 'calc((100vw - 32px) * 9 / 16)',
                      md: 207
                    },
                    position: 'relative'
                  }}
                >
                  <Image
                    src={car.picture || '/dash/img/placeholders/awaiting-image.jpg'}
                    layout="fill"
                    objectFit="cover"
                    priority
                  />
                  {['sold', 'scrapped'].includes(car.status.toLowerCase()) &&
                    <Box
                      sx={{
                        position: 'absolute',
                        p: 1,
                        top: 8,
                        left: 8,
                        color: 'white',
                        bgcolor: 'rgba(0, 0, 0, 0.3)',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {car.status.toLowerCase() === 'sold' ? <MonetizationOnIcon /> : <SubjectIcon />}
                      <Box sx={{ ml: 1 }}>{car.status}</Box>
                    </Box>
                  }
                </Box>
                <Divider />

                <CardActionArea
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    pt: 2
                  }}
                  onClick={() => manageCarJobs(car)}
                >
                  <Stack
                    direction="row"
                    spacing={2}
                  >
                    <Counter name="Jobs" value={car.job_count} />
                    <Counter name="Estimates" value={car.estimate_count} />
                    <Counter name="Completed" value={car.job_completed_count} />
                  </Stack>
                </CardActionArea>

                <CardContent>
                  <Row rowName="Year" rowValue={car.year} openEdit={() => openEdit(car)} />
                  <Divider />
                  <Row rowName="Engine" rowValue={car.engine} openEdit={() => openEdit(car)} />
                  <Divider />
                  <Row rowName="Colour" rowValue={car.colour} openEdit={() => openEdit(car)} />
                  <Divider />
                  <Row rowName="Mileage" rowValue={car.mileage && `${car.mileage} km`} openEdit={() => openEdit(car)} />
                  <Divider />
                  <Row rowName="Last Service" rowValue={car.last_service && `${car.last_service} km`} openEdit={() => openEdit(car)} />
                  <Divider />
                  <Row rowName="VIN" rowValue={car.vin} openEdit={() => openEdit(car)} />
                  <Divider />
                  <Row rowName="Registration" rowValue={car.registration} openEdit={() => openEdit(car)} />

                  {/* {scanned} */}
                  <Divider />
                  <Row rowName="Model no" rowValue={car.model_no} openEdit={() => openEdit(car)} />
                  <Divider />
                  <Row rowName="Engine no" rowValue={car.engine_no} openEdit={() => openEdit(car)} />
                  <Divider />
                  <Row rowName="Vehicle Type" rowValue={car.vehicle_type} openEdit={() => openEdit(car)} />
                  <Divider />
                  <Row rowName="License Expiry" rowValue={car.license_expiry_date} openEdit={() => openEdit(car)} />
                  <Divider />
                  <Row rowName="Last Updated" rowValue={car.modified} openEdit={() => openEdit(car)} />

                  {/* <Divider />
                  <Box sx={{ pt: 1 }}><Button size="small" sx={{ color: 'primary.light' }}>View Jobs</Button></Box> */}
                </CardContent>
                <CardActions
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Button
                    variant="contained"
                    startIcon={<EditIcon />}
                    onClick={() => openEdit(car)}
                    fullWidth={!isMobile}
                  >Edit</Button>
                  {isMobile && <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<BuildIcon />}
                    onClick={() => manageCarJobs(car)}
                    fullWidth={!isMobile}
                  >View Jobs</Button>}
                </CardActions>
              </Card>

              <MobileStepper
                variant="text"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                  <Button
                    size="small"
                    onClick={handleNext}
                    disabled={activeStep === maxSteps - 1}
                  >
                    Next 
                    <KeyboardArrowRight />
                  </Button>
                }
                backButton={
                  <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                    <KeyboardArrowLeft /> 
                    Previous
                  </Button>
                }
              />
            </Box>
          ))}
          <Box
            sx={{
              width: {
                xs: '100vw',
                md: '100%',
              },
              px: 1
            }}
          >
            <Card
              sx={{
                minWidth: 320,
                width: '100%',
              }}
            ></Card>
          </Box>
        </SwipeableViews>       

        {!!editOpen &&
          <EditCar open={!!editOpen} handleClose={closeEdit} car={cars && editOpen} />
        }
      </> :
      <Box
        sx={{
          // minHeight: 'calc(100vh - 56px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h4"
            sx={{
              my: 4
            }}
            color="primary"
          >Add your car</Typography>
          <Image
            src="/dash/img/illustrations/keys.png"
            width={240}
            height={238}
            quality={100}
            alt="Keys"
          />
          <Typography
            sx={{
              mt: 4,
              mb: 2
            }}
            color="text.secondary"
          >
            Create a digital profile for your car.
          </Typography>
          <Button
            color="secondary"
            variant="contained"
            size="large"
            component={NextLinkComposed}
            to={{ pathname: '/create-car/' }}
          >Start</Button>
        </Box>
      </Box>}
    </Box>
  );
}

export default MyCars;
