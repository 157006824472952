import {
  Box,
  Typography,
} from '@mui/material';


const Counter = ({ name, value, primary }) => {

  return (
    <Box>
      <Box
        sx={{
          width: 64,
          height: 64,
          lineHeight: '64px',
          borderRadius: '50%',
          borderColor: primary ? 'primary.main' : 'primary.light',
          // bgcolor: primary ? 'white' : null,
          borderWidth: '2px',
          borderStyle: 'solid',
          textAlign: 'center',
          color: 'secondary.main',
          fontWeight: 500,
        }}
      >
        {value || '- -'}
      </Box>
      <Typography
        variant="body2"
        textAlign="center"
        fontWeight={300}
        fontSize={12}
        sx={{ mt: 1 }}
      >{name}</Typography>
    </Box>
  )
}

export default Counter;
