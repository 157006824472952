import React from 'react';

import Image from 'next/image';
import { useRouter } from 'next/router';

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from '@mui/material';

import useSWR from 'swr';

import JobSummaryCard from './JobSummaryCard';


const MyCarJobs = ({ carId }) => {
  const router = useRouter();

  const url = carId && `/api/v2/my-car-jobs/${carId}/`;
  const { data: jobs, error } = useSWR(url);  // handle error

  if (!jobs && !error) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '80vh',
          width: '100%',
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    )
  }

  return (
    <Container
      maxWidth="sm"
      sx={{
        mt: 4,
        overflow: 'hidden',
        overflowY: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant="h4"
          color="primary.light"
          gutterBottom
        >Jobs</Typography>

        <Typography
          variant="body2"
          gutterBottom
        >Count: {jobs.length}</Typography>
      </Box>
      <Typography
        variant="body2"
        color="text.secondary"
        gutterBottom
        fontWeight={300}
      >Relevant mechanics and workshops are notified of these jobs and they can respond with cost estimates</Typography>

      {jobs.length > 0 ?
        <Stack
          sx={{ mt: 3 }}
          spacing={3}
        >
          {jobs.map((job) => <JobSummaryCard key={job.id} job={job} />)}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              pb: 2,
            }}>
            <Button
              fullWidth={false}
              variant="contained"
              color="secondary"
              onClick={() => router.push(`/create-job/${carId}/`)}
            >Create Job</Button>
          </Box>
        </Stack> :
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            minHeight: '75vh',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <Image
            src="/dash/img/illustrations/profile.svg"
            width={200}
            height={200}
          />
          <Typography
            variant="body1"
            sx={{ my: 2 }}
          >You haven't created any jobs for this car yet</Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => router.push(`/create-job/${carId}/`)}
          >Create Job</Button>
        </Box>
      }
    </Container>
  )
}

export default MyCarJobs;
