import { useState } from 'react';

import Head from 'next/head';

import {
  Box,
  Container,
  Grid,
  useMediaQuery
} from '@mui/material';

import MyCarJobs from '../components/MyCarJobs';
import MyCars from '../components/MyCars';
import TopNav from '../components/TopNav';


const Home = () => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
  const [carId, setCarId] = useState();

  const handleCarChange = (id) => {
    setCarId(id);
  }

  return (
    <Box>
      <Head>
        <title>My Cars - MechanicBuddy</title>
        {/* Maybe this could read: My Ford */}
      </Head>

      <TopNav />

      <Container disableGutters={isMobile}>
        <Grid container columnSpacing={4}>
          <Grid item xs={12} md={5}>
            <MyCars handleCarChange={handleCarChange} />
          </Grid>

          {carId != 0 && <Grid
            item
            md={7}
            sx={{
              display: {
                xs: 'none',
                md: 'block'
              }
            }}
          >
            <MyCarJobs carId={carId} />
          </Grid>}
        </Grid>       
      </Container>
    </Box>
  );
}

export default Home;
