import { forwardRef, useEffect, useRef, useState } from "react";

import Image from 'next/image';

import {
  Autocomplete,
  Box,
  Button,
  CardActionArea,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  LinearProgress,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  TextField,
  useMediaQuery,
  Zoom,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import MoreIcon from '@mui/icons-material/MoreVert';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

import DeleteIcon from '@mui/icons-material/Delete';
import SubjectIcon from '@mui/icons-material/Subject';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';

import { format } from 'date-fns';

import { Formik, Form, Field } from "formik";

import { Select, TextField as FormikTextField } from "formik-mui";

import * as Yup from "yup";

import useSWR, { mutate } from 'swr';

import axiosInstance from "../src/axiosInstance";

import CropImageDialog from "./CropImageDialog";


const COLOURS = [
  '',
  'White',
  'Silver',
  'Grey',
  'Black',
  'Blue',
  'Red',
  'Brown',
  'Green',
  'Gold',
  'Orange',
  'Other',
];


const validationSchema = Yup.object().shape({
  make: Yup.string(),
  make: Yup.string().required('Please provide the car make'),
  model: Yup.string().required('Please provide the car model'),
  year: Yup.number(),
  engine: Yup.string(),
  colour: Yup.string(),
  mileage: Yup.number(),
  last_service: Yup.number(),
  vin: Yup.string(),  // Add VIN validation  
  registration: Yup.string(),
  model_no: Yup.string(),
  engine_no: Yup.string(),
  license_expiry: Yup.string(),
});


const Transition = forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});


const DeleteCar = ({ open, handleClose, car }) => {

  const deleteCar = () => {
    axiosInstance.delete(
      `/api/v3/my-cars/${car.id}/`
    ).then(() => {
      mutate(`/api/v3/my-cars/`);
      handleClose(true);
    }).catch(
      error => console.log('error', error)
    )
  }

  return <Dialog
    open={open}
    onClose={handleClose}
  >
    <DialogTitle>Remove this car?</DialogTitle>
    <DialogContent>
      <DialogContentText>
        This car and all its related jobs wil be removed
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => handleClose(false)} color="primary" autoFocus>
        No
      </Button>
      <Button onClick={() => deleteCar()} color="primary" >
        Yes
      </Button>
    </DialogActions>
  </Dialog>
}


const SoldCar = ({ open, handleClose, car }) => {

  const soldCar = () => {
    axiosInstance.patch(
      `/api/v3/my-cars/${car.id}/`,
      {
        status: 'Sold'
      }
    ).then(() => {
      mutate(`/api/v3/my-cars/`);
      handleClose(true);
    }).catch(
      error => console.log('error', error)
    )
  }

  return <Dialog
    open={open}
    onClose={handleClose}
  >
    <DialogTitle>Is this car sold?</DialogTitle>
    <DialogContent>
      <DialogContentText>
        This car will be marked as sold and you won't be able to create new jobs for it.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => handleClose(false)} color="primary" autoFocus>
        No
      </Button>
      <Button onClick={() => soldCar()} color="primary" >
        Yes
      </Button>
    </DialogActions>
  </Dialog>
}


const ScrappedCar = ({ open, handleClose, car }) => {

  const scrappedCar = () => {
    axiosInstance.patch(
      `/api/v3/my-cars/${car.id}/`,
      {
        status: 'Scrapped'
      }
    ).then(() => {
      mutate(`/api/v3/my-cars/`);
      handleClose(true);
    }).catch(
      error => console.log('error', error)
    )
  }

  return <Dialog
    open={open}
    onClose={handleClose}
  >
    <DialogTitle>Is this car scrapped?</DialogTitle>
    <DialogContent>
      <DialogContentText>
        This car will be marked as scrapped and you won't be able to create new jobs for it.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => handleClose(false)} color="primary" autoFocus>
        No
      </Button>
      <Button onClick={() => scrappedCar()} color="primary" >
        Yes
      </Button>
    </DialogActions>
  </Dialog>
}


const EditCar = ({ open, handleClose, car }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
  const [openDelete, setOpenDelete] = useState(false);
  const [openSold, setOpenSold] = useState(false);
  const [openScrapped, setOpenScrapped] = useState(false);
  const [openCrop, setOpenCrop] = useState(false);
  
  const [make, setMake] = useState('');
  const [model, setModel] = useState('');
  const [year, setYear] = useState('');
  
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  
  const inputFile = useRef(null);
  const [inputImage, setInputImage] = useState();
  const [cropImage, setCropImage] = useState();
  const [uploadProgress, setUploadProgress] = useState();

  const { data: makes } = useSWR(`/api/v2/car-makes/`);

  const modelUrl = make && `/api/v2/car-models/?make=${make}`;
  const { data: models } = useSWR(modelUrl);

  const yearUrl = make && model && `/api/v2/car-years/?make=${make}&model=${model}`;
  const { data: years } = useSWR(yearUrl);

  const typeUrl = make && model && year && `/api/v2/car-types/?make=${make}&model=${model}&year=${year}`;
  const { data: car_types } = useSWR(typeUrl);

  useEffect(() => {
    if (car) {
      setMake(car.make);
      setModel(car.model);
      setYear(car.year);
    }
  }, [car])

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const closeDelete = (deleted) => {
    setOpenDelete(false);
    if (deleted) {
      handleClose();
    }
  }

  const closeSold = (sold) => {
    setOpenSold(false);
    if (sold) {
      handleClose();
    }
  }

  const closeScrapped = (scrapped) => {
    setOpenScrapped(false);
    if (scrapped) {
      handleClose();
    }
  }

  const closeCropImage = (image, redo) => {
    if (image) {
      setInputImage(image);
      uploadImage(image);
    } else if (redo) {
      inputFile.current.click()
    } else {
      setCropImage(null);
    }
    setOpenCrop(false);
  }

  const onUploadProgress = (progressEvent) => {
    const progress = progressEvent.loaded / progressEvent.total;
    setUploadProgress(progress);
  }

  const uploadImage = (image) => {
    if (uploadProgress == 100) return;

    const formData = new FormData();
    formData.append('picture', image, 'car.jpeg');

    axiosInstance.patch(`/api/v2/my-car-image/${car.id}/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progress) => onUploadProgress(progress),
    }).then(() => {
      mutate(`/api/v3/my-cars/`);
      setUploadProgress(0);
      setCropImage(null);
    }).catch(err =>{
      console.log('error', err);
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      fullScreen={isMobile}
      maxWidth="xs"
      fullWidth
    >
      <IconButton
        sx={{
          position: 'absolute',
          left: (theme) => theme.spacing(1),
          top: (theme) => theme.spacing(1),
          color: car.picture ? 'white': 'grey.500',
          zIndex: 1,
        }}
        onClick={() => handleClose()}
        size="large">
        <CloseIcon />
      </IconButton>

      <IconButton
        sx={{
          position: 'absolute',
          right: (theme) => theme.spacing(1),
          top: (theme) => theme.spacing(1),
          color: car.picture ? 'white': 'grey.500',
          zIndex: 1,
        }}
        onClick={handleMenuOpen}
        size="large">
        <MoreIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => {
          setOpenSold(true);
          handleMenuClose();
        }}>
          <ListItemIcon>
            <MonetizationOnIcon fontSize="small" />
          </ListItemIcon>
          Car Sold
        </MenuItem>
        <MenuItem onClick={() => {
          setOpenScrapped(true);
          handleMenuClose();
        }}>
          <ListItemIcon>
            <SubjectIcon fontSize="small" />
          </ListItemIcon>
          Car Scrapped
        </MenuItem>
        <MenuItem onClick={() => {
          setOpenDelete(true);
          handleMenuClose();
        }}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          Remove Car
        </MenuItem>
      </Menu>

      <input
        type="file"
        id="car-main-image"
        ref={inputFile}
        style={{ display: 'none' }}
        accept="image/jpeg, image/png"
        onChange={(event) => {
          const file = event.target.files[0];
          setCropImage(URL.createObjectURL(file));
          setOpenCrop(true);
        }}
      />
      <CardActionArea
        sx={{
          height: 'calc(100vw * 9 / 16)',
          position: 'relative',
          minHeight: 210
        }}
        onClick={() => inputFile.current.click()}
      >
        {inputImage ?
          <img
            src={URL.createObjectURL(inputImage)}
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'cover',
              opacity: uploadProgress > 0 ? uploadProgress * 0.8 + 0.2 : 1
            }}
          /> :
          <Image
            src={car.picture || '/dash/img/placeholders/awaiting-image.jpg'}
            layout="fill"
            objectFit="cover"
          />
        }
        <PhotoCameraIcon
          sx={{
            position: 'absolute',
            right: (theme) => theme.spacing(3),
            bottom: (theme) => theme.spacing(3),
            zIndex: 1,
            color: 'secondary.main'
          }}
        />
      </CardActionArea>

      {uploadProgress > 0 && <Box>
        <LinearProgress
          variant="determinate"
          value={uploadProgress * 100}
          color="secondary"
          sx={{
            zIndex: 100
          }}
        />
      </Box>}

      <Box p={2}>
        <Formik
          initialValues={{
            ...car,
            year: car.year || '',
            mileage: car.mileage || '',
            last_service: car.last_service || '',
            license_expiry_date: car.license_expiry_date ? new Date(car.license_expiry_date) : '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            axiosInstance.patch(
              `/api/v3/my-cars/${car.id}/`,
              {
                ...values,
                year: values.year || null,
                mileage: values.mileage || null,
                last_service: values.last_service || null,
                license_expiry_date: values.license_expiry_date ? format(values.license_expiry_date, 'yyyy-MM-dd') : null,
              }
            ).then(() => {
              // add a snackbar for feedback
              mutate(`/api/v3/my-cars/`);
              setSubmitting(false);
              handleClose();
            }).catch(error => {
              console.log('error', error);
              setSubmitting(false);
            })
          }}
        >
          {({ submitForm, isValid, isSubmitting, values, setFieldValue, touched, errors, setFieldTouched }) => (
            <Form>
              <Stack spacing={2}>
                <Field
                  name="name"
                  component={FormikTextField}
                  label="Name"
                  fullWidth
                />

                <Autocomplete
                  name="make"
                  value={values['make']}
                  onChange={(event, value) => {
                    setFieldValue('make', value || '');
                    setFieldValue('model', '');

                    setTimeout(() => setFieldTouched('make', true));
                    setTimeout(() => setFieldTouched('model', true));

                    setFieldValue('year', '');
                    setFieldValue('engine', '');

                    setMake(value);
                    setModel('');
                    setYear('');
                  }}
                  options={makes || []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Make"
                      error={touched['make'] && Boolean(errors['make'])}
                      helperText={touched['make'] && errors['make']}
                    />
                  )}
                  loading={!makes}
                />

                <Autocomplete
                  name="model"
                  value={values['model']}
                  onChange={(event, value) => {
                    setFieldValue('model', value || '');
                    setTimeout(() => setFieldTouched('model', true));

                    setFieldValue('year', '');
                    setFieldValue('engine', '');

                    setModel(value);
                    setYear('');
                  }}
                  options={models || []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Model"
                      error={touched['model'] && Boolean(errors['model'])}
                      helperText={touched['model'] && errors['model']}
                    />
                  )}
                  loading={!models}
                  disabled={!make}
                />

                <Autocomplete
                  name="year"
                  value={values['year']}
                  onChange={(event, value) => {
                    setFieldValue('year', value || '');
                    setFieldValue('engine', '');

                    setYear(value);
                  }}
                  options={years || []}
                  renderInput={(params) => <TextField {...params} label="Year" />}
                  loading={!years}
                  getOptionLabel={option => option.toString()}
                  disabled={!model}
                />

                <Autocomplete
                  name="engine"
                  value={values['engine']}
                  onChange={(event, value) => {
                    setFieldValue('engine', value || '');
                  }}
                  options={car_types || []}
                  renderInput={(params) => <TextField {...params} label="Engine" />}
                  loading={!car_types}
                  disabled={!year}
                />

                <FormControl fullWidth>
                  <Field
                    component={Select}
                    name="colour"
                  >
                    {COLOURS.map((value) => (
                      <MenuItem key={value} value={value}>{value || 'Not sure'}</MenuItem>
                    ))}
                  </Field>
                </FormControl>

                <Field
                  name="mileage"
                  component={FormikTextField}
                  label="Mileage"
                  type="number"
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="end">km</InputAdornment>
                  }}
                />

                <Field
                  name="last_service"
                  component={FormikTextField}
                  label="Last Service"
                  type="number"
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="end">km</InputAdornment>
                  }}
                />

                <Field
                  name="vin"
                  component={FormikTextField}
                  label="VIN"
                  fullWidth
                />

                <Field
                  name="registration"
                  component={FormikTextField}
                  label="Registration"
                  fullWidth
                />

                <Field
                  name="model_no"
                  component={FormikTextField}
                  label="Model no"
                  fullWidth
                />

                <Field
                  name="engine_no"
                  component={FormikTextField}
                  label="Engine no"
                  fullWidth
                />

                <Field
                  name="vehicle_type"
                  component={FormikTextField}
                  label="Vehicle Type"
                  fullWidth
                />

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    name="license_expiry_date"
                    label="License Expiry"
                    value={values['license_expiry_date']}
                    onChange={(newValue) => {
                      setFieldValue('license_expiry_date', newValue || '');
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={touched['license_expiry_date'] && Boolean(errors['license_expiry_date'])}
                        helperText={touched['license_expiry_date'] && errors['license_expiry_date']}
                      />
                    )}
                    inputFormat="yyyy-MM-dd"
                    clearable={true}
                  />
                </LocalizationProvider>

                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => submitForm()}
                  disabled={!isValid || isSubmitting}
                >
                  Update
                </Button>

                <Button
                  color="error"
                  onClick={() => setOpenDelete(true)}
                >
                  Remove
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </Box>

      <DeleteCar open={openDelete} handleClose={closeDelete} car={car} />
      <SoldCar open={openSold} handleClose={closeSold} car={car} />
      <ScrappedCar open={openScrapped} handleClose={closeScrapped} car={car} />

      {(openCrop || cropImage) && <CropImageDialog open={openCrop} handleClose={closeCropImage} cropImage={cropImage} />}
    </Dialog>
  );
}

export default EditCar;
