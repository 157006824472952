import React from 'react';

import { useRouter } from 'next/router';

import {
  Box,
  Card,
  CardActionArea,
  Stack,
  Typography,
} from '@mui/material';

import EventIcon from '@mui/icons-material/Event';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import Counter from './Counter';
import ServiceIcon from './ServiceIcon';


const JobSummaryCard = ({ job }) => {
  const router = useRouter();

  const getStatus = () => {
    if (job.status == 'paid') {
      return `Paid invoice of R${job.accepted_invoice_cost}` // from Business
    } else if (job.accepted_invoice_cost) {
      return `Accepted invoice of R${job.accepted_invoice_cost}` // from Business
    } else if (job.invoice_count) {
      return `Invoice received` // from Business
    } else if (job.accepted_estimate_cost) {
      return `Accepted estimate of R${job.accepted_estimate_cost}` // from Business
    } else if (job.status == 'unapproved') {
      return 'Awaiting confirmation'  // By MechanicBuddy
    } else if (job.status == 'cancelled') {
      return 'Cancelled'  // When
    } else if (job.status == 'completed') {
      return 'Completed'  // By
    } else if (job.estimate_count > 0) {
      return `${job.estimate_count} estimate${job.estimate_count === 1 ? '' : 's'}`  // to choose from
    } else {
      return 'No estimates yet'  // From businesses
    }
    // Booking
    // Invoice
  }

  return (
    <Card variant="outlined">
      <CardActionArea
        component="div"
        onClick={() => router.push({
          pathname: `/my-jobs/${job.car}/`,
          query: {
            jobId: job.id
          }
        })}
        sx={{
          flexGrow: 1,
          p: 2,
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <ServiceIcon service={job.job_type.category} width={48} height={48} />
          <Box sx={{ ml: 1 }}>
            <Typography variant="body2" color="text.secondary">{getStatus()}</Typography>
            <Typography variant="h6">{ job.job_type.name }</Typography>
          </Box>
        </Box>

        <Box
          sx={{
            mt: 2,
            ml: 1.5,
            display: "flex",
            alignItems: "center",
            py: 0.5,
            color: "text.secondary"
          }}
        >
          <EventIcon fontSize="small" />
          <Typography
            variant="body2"
            sx={{
              pl: 1
            }}
          >{ job.when }</Typography>
        </Box>
        <Box
          sx={{
            ml: 1.5,
            display: "flex",
            alignItems: "center",
            py: 0.5,
            color: "text.secondary"
          }}>
          <LocationOnIcon fontSize="small" />
          <Typography
            variant="body2"
            sx={{
              pl: 1
            }}
          >{ job.where }</Typography>
        </Box>

        <Stack
          direction="row"
          spacing={2}
          sx={{
            m: 2,
            ml: 1.5,
            mb: 0,
          }}
        >
          <Counter name="Estimates" value={job.estimate_count} />
          <Counter name="Lowest" value={job.lowest_estimate && 'R' + job.lowest_estimate} />
          <Counter name="Highest" value={job.highest_estimate && 'R' + job.highest_estimate} />
          {job.accepted_invoice_cost ?
            <Counter
              name="Invoice"
              value={'R' + job.accepted_invoice_cost}
              primary={true}
            />
          : job.received_invoice_cost ?
            <Counter
              name="Invoice"
              value={'R' + job.received_invoice_cost}
              primary={true}
            />
          : job.accepted_estimate_cost &&
            <Counter
              name="Accepted"
              value={'R' + job.accepted_estimate_cost}
              primary={true}
            />
          }
        </Stack>

        <Box
          sx={{
            position: "absolute",
            right: 4,
            bottom: 2,
            fontSize:10,
            color: 'text.secondary',
          }}
        >
          {job.created}
        </Box>
      </CardActionArea>
    </Card>
  )
}

export default JobSummaryCard;
