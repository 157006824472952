import { useCallback, useState } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import ReplayIcon from '@mui/icons-material/Replay';

import Cropper from 'react-easy-crop';


const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.src = url
  })


const CropImageDialog = ({ open, handleClose, cropImage }) => {

  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, [])

  const onCropped = async () => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    // const image = new Image();
    const image = await createImage(cropImage);

    canvas.width = image.width;
    canvas.height = image.height;
    
    const { x, y, width, height } = croppedAreaPixels;
    
    ctx.drawImage(image, 0, 0);

    const data = ctx.getImageData(x, y, width, height);

    canvas.width = width;
    canvas.height = height;

    ctx.putImageData(data, 0, 0);

    canvas.toBlob((file) => {
      handleClose(file);
    }, 'image/jpeg');
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen
    >
      <DialogTitle>Updating car image ...</DialogTitle>
      <IconButton
        sx={{
          position: 'absolute',
          right: (theme) => theme.spacing(1),
          top: (theme) => theme.spacing(1),
          zIndex: 1,
        }}
        onClick={() => handleClose()}
        size="large">
        <CloseIcon />
      </IconButton>

      <Box
        sx={{
          position: 'relative',
          minHeight: 400,
          height: 'calc(100vh - 56px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Cropper
          image={cropImage}
          crop={crop}
          zoom={zoom}
          aspect={16 / 9}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />

        <Box
          sx={{
            display: 'flex',
            position: 'absolute',
            bottom: 48
          }}
        >
          <Button
            color="secondary"
            variant="contained"
            size="large"
            onClick={() => onCropped()}
          >Continue</Button>

          <IconButton
            sx={{ ml: 2 }}
            size="large"
            onClick={() => handleClose(null, true)}
          >
            <ReplayIcon fontSize="inherit" color="secondary" />
          </IconButton>
        </Box>
      </Box>
    </Dialog>
  );
}

export default CropImageDialog;
